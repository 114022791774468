import { ELogType } from "@/data";
import { capitalize, getFormattedHour, formatFirstnameLastname, capitalizeAllString, concat } from "@/helpers/commons";
import i18n from "@/i18n";
import store from "@/store";
import { IWorkerLogsContent, ILogDB, ILoggerLog, IInterviewDB, ILoggerAgency, JobOfferTableData, ApplicationType, IUserDB } from "@/types";
import moment from "moment";

export default function useWorkerLogsFormater({
    logs,
    worker,
    collaboratorId,
    workerJobOffers,
}: {
    logs: ILogDB<IWorkerLogsContent>[];
    worker: any;
    collaboratorId: string;
    workerJobOffers?: JobOfferTableData[];
}): ILoggerLog[] {
    const candidateName = formatFirstnameLastname(`${worker.firstname} ${worker.lastname}`);
    const nbSelectedAgencies = worker.agencies?.length || 0;
    const interviews = worker.interviews || [];
    let collaborator: IUserDB;

    return logs.map((log) => {
        if (log.collaborator) collaborator = store.getters.getCollaborator(log.collaborator._id.toString());
        const isCollaboratorOwner = collaboratorId === log.collaborator?._id;
        const agencyName = capitalizeAllString(log.agency ? log.agency.name : "") || "";
        const actionDoneBy = nbSelectedAgencies > 1 ? agencyName : "";
        const collaboratorName =
            collaborator || isCollaboratorOwner
                ? formatFirstnameLastname(log?.collaborator?.name)
                : i18n.t("page.candidate.logs.byWorker").toString();

        let formatedLog: ILoggerLog = {
            _id: log._id.toString(),
            timestamp: log.timestamp,
            dateFormated: moment(log.createdAt).format("D MMM YYYY").toString(),
            timeFormated: moment(log.timestamp).format("HH[h]mm").toString(),
            title: i18n
                .t(`page.candidate.logs.${log.type}.text`, {
                    collaborator: collaboratorName || "L'agence " + agencyName,
                    candidate: candidateName,
                })
                .toString(),
            icon: i18n.t(`page.candidate.logs.${log.type}.icon`) as string,
            commentary: actionDoneBy,
            author: concat([collaboratorName, agencyName], " - "),
            description: [],
            logType: log.type,
        };

        if (log.type === ELogType.CREATE) {
            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.${log.collectionOrigin}.text`, {
                    collaborator: collaboratorName || "L'agence " + agencyName,
                    candidate: candidateName,
                })
                .toString();
        }

        if (log.type === ELogType.ADD_AGENCY) {
            const agencies = log.content.originAgencies?.map((agency: ILoggerAgency) => agency.name).join(", ");

            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.${log.collectionOrigin}.text`, {
                    collaborator: collaboratorName || "L'agence " + agencyName,
                    candidate: candidateName,
                    agencies: agencies,
                })
                .toString();
        }

        if (log.type === ELogType.IMPORT_EVOLIA) {
            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.${log.collectionOrigin}.text`, {
                    collaborator: collaboratorName || "L'agence " + agencyName,
                    candidate: candidateName,
                })
                .toString();
        }

        if (log.type === ELogType.CREATE_FROM_INTERIM) {
            const agencies = log.content.originAgencies?.map((agency: ILoggerAgency) => agency.name).join(", ");

            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.text`, {
                    collaborator: collaboratorName || "L'agence " + agencyName,
                    candidate: capitalize(candidateName),
                    agencies: agencies,
                })
                .toString();
        }

        if (log.type === ELogType.NOTE) {
            formatedLog.description = [{ type: "textarea", editable: isCollaboratorOwner, text: log.content.internalComment || "" }];
            formatedLog.author = log.collaborator ? concat([collaboratorName, agencyName], " - ") : agencyName;
        }

        if (log.type === ELogType.CONTACT) {
            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.sub-type.${log.content.contactType}.text`, {
                    collaborator: log.collaborator?.name,
                    candidate: candidateName,
                })
                .toString();
            formatedLog.icon = i18n.t(`page.candidate.logs.${log.type}.sub-type.${log.content.contactType}.icon`) as string;
            if (log?.content?.internalComment) {
                formatedLog.description = [
                    {
                        type: "textarea",
                        editable: collaboratorId === log.collaborator?._id,
                        text: log.content.internalComment || "",
                    },
                ];
            }
        }

        if (log.type === ELogType.INTERVIEW) {
            const itw = interviews.find((itw: IInterviewDB) => itw._id.toString() === log.content.interviewId?.toString());
            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.text`, {
                    date: moment(itw?.date).format("DD MMM YYYY").toString(),
                    collaborator: candidateName,
                })
                .toString();
            formatedLog.description = [
                {
                    type: "block",
                    editable: collaboratorId === log.collaborator?._id,
                    text: "",
                    date: moment(itw?.date).format("D MMM YYYY").toString(),
                    startHour: getFormattedHour(itw?.start || 0, "date.time").toString(),
                    endHour: getFormattedHour(itw?.end || 0, "date.time").toString(),
                    collaborator: log.collaborator?.name,
                },
            ];
            if (log.content?.internalComment) {
                formatedLog.description?.push({
                    type: "textarea",
                    editable: collaboratorId === log.collaborator?._id,
                    text: log.content.internalComment,
                });
            }
        }

        if (log.type === ELogType.CHANGE_STATUS) {
            formatedLog.title = i18n.t(`page.candidate.logs.${log.type}.sub-type.${log.content.statusAfter}.text`, {
                collaborator: log.collaborator?.name,
                candidate: candidateName,
            }) as string;
            formatedLog.icon = i18n.t(`page.candidate.logs.${log.type}.sub-type.${log.content.statusAfter}.icon`) as string;
            if (log.content?.internalComment) {
                formatedLog.description = [
                    { type: "textarea", editable: collaboratorId === log.collaborator?._id, text: log.content.internalComment },
                ];
            }
        }

        if (log.type === ELogType.FEEDBACK) {
            if (log.content?.internalComment) {
                formatedLog.description = [
                    { type: "textarea", editable: collaboratorId === log.collaborator?._id, text: log.content.internalComment },
                ];
            }
        }

        if (log.type === ELogType.NEW_APPLICATION) {
            if (log.content.applicationType === ApplicationType.spontaneous) {
                formatedLog.title = i18n
                    .t(`page.candidate.logs.${log.type}.${log.content.applicationType}`, {
                        source: log.content.origin,
                    })
                    .toString();
                formatedLog.author = "";
                return formatedLog;
            }

            const offerName = workerJobOffers?.find((offer) => offer._id === log.content.jobOfferId)?.name;

            if (!offerName) {
                formatedLog.title = "";
                return formatedLog;
            }

            formatedLog.title = i18n
                .t(`page.candidate.logs.${log.type}.${log.content.applicationType}`, {
                    jobOfferName: offerName,
                    collaborator: log.collaborator?.name,
                    candidate: candidateName,
                })
                .toString();
        }

        if (log.type === ELogType.INACTIVE && log.content?.reason) {
            formatedLog.description = [
                {
                    type: "textarea",
                    editable: false,
                    text:
                        log.content.reason !== "autre"
                            ? i18n.t(`page.candidate.logs.${log.type}.reason.${log.content.reason}`).toString()
                            : i18n
                                  .t(`page.candidate.logs.${log.type}.reason.${log.content.reason}`, { reason: log.content.internalComment })
                                  .toString(),
                },
            ];
        }

        if (log.type === ELogType.UPDATE_PROFILE) {
            if (log.content.updated) {
                formatedLog.description = [{ type: "block", editable: false, updated: log.content.updated }];
            }
        }

        return formatedLog;
    });
}
