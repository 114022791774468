import { render, staticRenderFns } from "./WorkerFollowingSideModal.vue?vue&type=template&id=2ef85752&scoped=true&"
import script from "./WorkerFollowingSideModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WorkerFollowingSideModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./WorkerFollowingSideModal.vue?vue&type=style&index=0&id=2ef85752&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef85752",
  null
  
)

export default component.exports